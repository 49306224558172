<template>
  <div
    class="qchat-messenger__heading q-d-flex q-justify-content-between"
    :style="styles.headerPanel[!user ? 'form' : 'conversation'].style.panel"
  >
    <div v-if="!user">
      <p
        class="qchat-messenger__heading-title"
        style="margin: 0"
        :style="styles.headerPanel.form.style.title"
      >
        {{ styles.headerPanel.form.content.title }}
      </p>
      <p
        style="margin: 10px 0 0 0"
        :style="styles.headerPanel.form.style.description"
      >
        {{ styles.headerPanel.form.content.description }}
      </p>
    </div>
    <div v-else class="q-d-flex">
      <p class="q-m-0" :style="styles.headerPanel.conversation.style.title">
        {{
          styles.headerPanel.conversation.content[
            user.extra.is_participant_online ? "online" : "offline"
          ]
        }}
        <span class="q-pointer q-ml-1" @click="logout"><u>Logout</u></span>
      </p>
    </div>
    <div class="q-pointer q-ml-1" @click="$emit('close')">
      <Icons
        class="qchat-icon__close"
        type="la-times"
        size="24"
        :fill="styles.floatingButton.color"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
const Icons = () => import("../../Icons/index.vue");

export default {
  computed: {
    ...mapState("messenger", ["user", "styles"])
  },
  components: { Icons },
  methods: {
    logout() {
      this.showNotification(`Succesfully logged out!`, "", 5000);
      localStorage.removeItem("qchat_user");
      localStorage.removeItem("qchat_room_id");
      this.$store.commit("messenger/UPDATE_USER", null);
    }
  }
};
</script>
